<template>
  <div class="min-h-screen flex flex-col items-center justify-center p-4">
    <!-- 主要内容区 -->
    <div class="w-full max-w-2xl bg-white rounded-xl shadow-2xl overflow-hidden border border-gray-300">
      <div class="p-8 pt-16">
        <h1 class="text-4xl font-extrabold text-center text-gray-800 mb-6">
          开始您的写作之旅
        </h1>

        <p class="text-center text-gray-600 mb-8">
          释放您的创造力，开始您的下一部杰作。
        </p>

        <div class="relative mb-4">
          <input v-model="userInput" type="text" placeholder="输入您的故事标题..."
            class="w-full px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300" />
          <button @click="goToDetailPage"
            class="absolute right-2 top-2 px-4 py-1 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-300 flex items-center space-x-2">
            <span>开始写作</span>
            <PenIcon class="w-5 h-5" />
          </button>
        </div>

        <!-- 灵感气泡 -->
        <div class="flex flex-wrap gap-2 mb-8">
          <div v-for="prompt in inspirations" :key="prompt" @click="selectPrompt(prompt)"
            class="px-3 py-1 bg-gray-100 rounded-full text-sm text-gray-700 hover:bg-gray-200 cursor-pointer transition duration-300">
            {{ prompt }}
          </div>
        </div>

        <!-- 最近的稿件 -->
        <div class="mt-8" v-if="recentDrafts.length > 0">
          <h2 class="text-xl font-semibold text-gray-700 mb-4">最近稿件</h2>
          <div class="space-y-4">
            <div v-for="draft in recentDrafts" :key="draft.id"
              class="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition duration-300 cursor-pointer flex justify-between items-center"
              @click="openDraft(draft.draft_id)">
              <div>
                <h3 class="font-medium text-gray-800">{{ draft.title }}</h3>
                <p class="text-sm text-gray-600">最后编辑于：{{ draft.lastEdited }}</p>
              </div>
              <!-- 删除图标 -->
              <TrashIcon @click.stop="deleteDraft(draft.draft_id)"
                class="w-6 h-6 text-gray-300 hover:text-gray-500 cursor-pointer transition duration-300" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @click="goToNewEditer('claude-35-haiku')" class="mt-2"> claude-35-haiku</div>
    <div @click="goToNewEditer('ClaudeComputerUse')" class="mt-2"> claude-computer-use</div>
    <div @click="goToNewEditer('gpt')" class="mt-2"> gpt</div>
    <div @click="goToNewEditer('zhipu')" class="mt-2"> zhipu</div>
    <div @click="goToNewEditer('lingyiwanwu')" class="mt-2"> lingyiwanwu</div>
    <div @click="goToNewEditer('o1Preview')" class="mt-2"> o1Preview</div>
    <div @click="goToNewEditer('o1')" class="mt-2"> o1</div>
    <div @click="goToNewEditer('xai')" class="mt-2"> grok-beta</div>
    <div @click="goToNewEditer('mistral-large-latest')" class="mt-2"> mistral-large-latest</div>
    <div @click="goToNewEditer('llama')" class="mt-2"> llama 405b</div>
    <div @click="goToNewEditer('qwen')" class="mt-2"> qwen</div>
    <div @click="goToNewEditer('doubao')" class="mt-2"> doubao</div>
    <div @click="goToNewEditer('gemini')" class="mt-2"> gemini</div>
    <div @click="goToNewEditer('deepseek')" class="mt-2"> deepseek</div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { PenIcon, TrashIcon } from 'lucide-vue-next';

const userInput = ref('');
const recentDrafts = ref([]);
const router = useRouter();

const inspirations = [
  "神秘的来信...",
  "梦想成真的世界...",
  "古老钟楼的秘密...",
  "时间旅行的开始..."
];

// 获取或创建匿名用户ID
const getOrCreateUserId = async () => {
  let userId = localStorage.getItem('user_id');
  if (!userId) {
    const response = await fetch('/api/user/create', {
      method: 'POST',
    });
    const data = await response.json();
    if (data.user_id) {
      userId = data.user_id;
      localStorage.setItem('user_id', userId);
    }
  }
  return userId;
};

// 获取用户的稿件列表
const fetchRecentDrafts = async () => {
  const userId = await getOrCreateUserId();
  if (userId) {
    const response = await fetch(`/api/write/list?user_id=${userId}`);
    const data = await response.json();
    recentDrafts.value = data.drafts;
  }
};

// 删除草稿
const deleteDraft = async (draftId) => {
  const userId = await getOrCreateUserId();
  const response = await fetch('/api/write/delete', {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      draft_id: draftId,
      user_id: userId
    })
  });

  const data = await response.json();
  if (data.message === 'Draft deleted successfully') {
    recentDrafts.value = recentDrafts.value.filter(draft => draft.draft_id !== draftId);
  } else {
    console.error('Failed to delete draft:', data.error);
  }
};

// 页面挂载时，获取最近稿件
onMounted(() => {
  fetchRecentDrafts();
});

// 开始新稿件
const goToDetailPage = async () => {
  if (userInput.value.trim()) {
    const userId = await getOrCreateUserId();
    if (userId) {
      const response = await fetch('/api/write/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id: userId,
          title: userInput.value
        })
      });
      const data = await response.json();
      if (data.draft_id) {
        router.push({
          path: `/detail/${data.draft_id}`,
        });
      }
    }
  }
};

// 选择灵感气泡填入标题
const selectPrompt = (prompt) => {
  userInput.value = prompt;
};

const goToNewEditer = (model) => {
  // userInput.value = prompt;
  if (model == 'claude-computer-use') {
    router.push({
      path: `/ClaudeComputerUse`,
    });
  } else if (model == 'gpt') {
    router.push({
      path: `/gpt`,
    });
  } else if (model == 'zhipu') {

    router.push({
      path: `/zhipu`,
    });
  } else if (model == 'lingyiwanwu') {

    router.push({
      path: `/lingyiwanwu`,
    });
  } else if (model == 'o1Preview') {

    router.push({
      path: `/o1Preview`,
    });
  } else if (model == 'o1') {

    router.push({
      path: `/o1`,
    });

  } else if (model == 'xai') {

    router.push({
      path: `/xai`,
    });
    //llama
  } else if (model == 'mistral-large-latest') {

    router.push({
      path: `/mistral`,
    });

  } else if (model == 'llama') {

    router.push({
      path: `/llama`,
    });
  } else if (model == 'qwen') {

    router.push({
      path: `/qwen`,
    });

  } else if (model == 'doubao') {

    router.push({
      path: `/doubao`,
    });
  } else if (model == 'claude-35-haiku') {

    router.push({
      path: `/claude-35-haiku`,
    });
  } else if (model == 'gemini') {

    router.push({
      path: `/gemini`,
    });
  } else if (model == 'deepseek') {

    router.push({
      path: `/deepseek`,
    });
  }

  // claude-35-haiku

};


// 打开现有稿件
const openDraft = (draftId) => {
  router.push(`/detail/${draftId}`);
};
</script>
