<template>
  <div class="flex h-screen">
    <!-- 左侧：版本历史记录 -->
    <div class="w-[200px] border-r border-gray-200 flex flex-col h-full ">
      <!-- 历史版本列表 -->
      <div class="flex justify-center items-center bg-white border-b border-gray-200 h-[46px]">
        版本记录
      </div>

      <ul class="divide-y divide-gray-200 flex-grow overflow-y-auto">
        <li v-for="(version, index) in versionHistory" :key="index" class="p-3 hover:bg-gray-100 cursor-pointer"
          @click="restoreVersion(index)">
          版本 {{ versionHistory.length - index }}
          <span class="text-xs text-gray-500 block">{{ version.timestamp }}</span>
        </li>
      </ul>

      <!-- 导出和导入按钮 -->
      <div class="p-3 flex justify-between items-center border-t border-gray-200">
        <button
          class="bg-gray-200 text-gray-700 px-4 py-1 rounded flex-grow mr-2 flex items-center justify-center hover:bg-gray-300 transition-colors"
          @click="exportVersionHistory">
          <span class="mr-2" style="font-size: 1.2em;">&#8595;</span>
          导出
        </button>
        <label
          class="bg-gray-200 text-gray-700 px-4 py-1 rounded flex-grow cursor-pointer text-center hover:bg-gray-300 transition-colors flex items-center justify-center">
          <span class="mr-2" style="font-size: 1.2em;">&#8593;</span>
          导入
          <input type="file" @change="importVersionHistory" class="hidden">
        </label>
      </div>
    </div>


    <!-- 中间：带标签页的编辑器 -->
    <div class="flex-1 flex flex-col overflow-hidden">
      <!-- 标签页 -->
      <div class="flex justify-between items-center bg-white border-b border-gray-200 h-[46px]">
        <!-- 标签页 -->
        <div class="flex">
          <div @click="switchToContent"
            :class="['relative px-4 py-2 font-medium cursor-pointer', activeTab === 'content' ? 'text-blue-600' : 'text-gray-500']">
            内容
            <span v-if="activeTab === 'content'" class="absolute bottom-0 left-0 w-full h-[2px] bg-blue-600"></span>
          </div>
          <div @click="activeTab = 'data'"
            :class="['relative px-4 py-2 font-medium cursor-pointer', activeTab === 'data' ? 'text-blue-600' : 'text-gray-500']">
            数据
            <span v-if="activeTab === 'data'" class="absolute bottom-0 left-0 w-full h-[2px] bg-blue-600"></span>
          </div>
        </div>

        <!-- 复制和下载按钮 -->
        <div class="flex space-x-2 mr-4">
          <button @click="copyContent"
            class="bg-gray-200 text-gray-700 px-4 py-1 rounded hover:bg-gray-300 transition-colors">
            复制
          </button>
          <button @click="downloadContent"
            class="bg-gray-200 text-gray-700 px-4 py-1 rounded hover:bg-gray-300 transition-colors">
            下载
          </button>
        </div>
      </div>

      <!-- 内容标签页 -->
      <div v-if="activeTab === 'content'" class="flex-1 overflow-y-auto">
        <div class="h-full bg-white p-2">
          <textarea v-model="editorContent" class="w-full h-full resize-none outline-none bg-transparent"
            @input="updateBlocks" @keydown.enter="handleEnter" />
        </div>
      </div>

      <!-- 数据标签页 -->
      <div v-else-if="activeTab === 'data'" class="flex-1 overflow-y-auto p-6">
        <pre class="text-sm overflow-x-auto">{{ JSON.stringify(blocks, null, 2) }}</pre>
      </div>
    </div>

    <!-- 右侧：模型选择和聊天组件 -->
    <div class="w-[400px] flex flex-col border-l border-gray-200">
      <!-- 模型选择下拉菜单 -->
      <div class="flex justify-center items-center  p-2 border-b border-gray-200 h-[46px]">
        <select v-model="selectedModel" class="p-1 text-sm border border-gray-300 rounded">
          <option v-for="model in models" :key="model" :value="model">
            {{ model }}
          </option>
        </select>
      </div>
      <!-- 聊天组件 -->
      <chat :model="selectedModel" :tools="tools" :appDataSync="appDataSync" :getAppData="getAppData" :runTool="runTool"
        ref="Ai" />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick } from 'vue';
// import chat from '../../components/AI/chat/anthropic/stream.vue';
import chat from '../components/AI/chat/gemini';
import { post } from '../utils';

// 模型列表
const models = [
  'gemini-1.5-pro-002',
  'gemini-exp-1114',
  'gemini-exp-1121',
  'gemini-2.0-flash-exp',
  'gemini-exp-1206'
            
];

const selectedModel = ref('gemini-exp-1114');
const appDataSync = ref(true);
const activeTab = ref('content');
const editorContent = ref('');


// 生成随机6位字符串作为 block id
const generateRandomId = () => Math.random().toString(36).substring(2, 8);
// 块列表，基于换行符将内容解析成多个段落
const blocks = ref([
  { id: generateRandomId(), type: 'text', content: '' }
]);

// const blockRefs = ref({});
const versionHistory = ref([]);  // 添加 versionHistory 的声明


// 切换到内容标签页
const switchToContent = () => {
  activeTab.value = 'content';  // 将当前活动的标签页设为 'content'
  nextTick(() => {
    updateBlocks();  // 确保切换后更新块内容
  });
};


const getAppData = () => {
  return `你是一个专门解决写作的人工智能助手，你能够通过工具操作用户的编辑器，实现用户的撰写需求。

    必须注意:
    如果所有需要执行的工具已经执行完毕，不要重复执行，而是回答用户。
    工具仅限于撰写修改等操作，回复用户必须要用对话的方式回复，不要用工具撰写对话内容。
    当你完成编辑后，你不需要重复刚才撰写的内容。
    所有的编辑操作，必须通篇考虑最终的文章成果，修改后的文章是通顺的，整洁的，符合用户需求的，不要产生重复的、错乱的、无效的内容，

    多次调用：
    面对复杂的需求，你需要需要按照先后顺序多次执行工具实现。

以下是当前编辑器的结构和内容：`
    + JSON.stringify(blocks.value)
};

// 工具列表
const tools = ref(
  [
    {
      name: "write",
      description: "在编辑器中开始书写，如果编辑器已经存在内容，则会在内容后面续写",
      parameters: {
        type: "object",
        properties: {
          content: {
            type: "string"
          }
        },
        required: ["content"]
      }
    },
    // {
    //   name: "edit",
    //   description: "编辑指定块的内容",
    //   parameters: {
    //     type: "object",
    //     properties: {
    //       blockId: {
    //         type: "string"
    //       },
    //       newContent: {
    //         type: "string"
    //       }
    //     },
    //     required: ["blockId", "newContent"]
    //   }
    // },
    // {
    //   name: "insert",
    //   description: "在指定段落之后插入新段落",
    //   parameters: {
    //     type: "object",
    //     properties: {
    //       blockId: {
    //         type: "string"
    //       },
    //       newContent: {
    //         type: "string"
    //       }
    //     },
    //     required: ["blockId", "newContent"]
    //   }
    // },
    // {
    //   name: "delete",
    //   description: "删除指定段落",
    //   parameters: {
    //     type: "object",
    //     properties: {
    //       blockId: {
    //         type: "string"
    //       }
    //     },
    //     required: ["blockId"]
    //   }
    // },

    // {
    //   name: "clear",
    //   description: "清空编辑器中的所有内容",
    //   parameters: {
    //     type: "object",
    //     properties: {},
    //     required: []
    //   }
    // },

    // {
    //   name: "clear",
    //   description: "清空编辑器中的所有内容",
    //   parameters: {
    //     type: "object",
    //     properties: {
    //       reason: {
    //         type: "string"
    //       }
    //     },
    //     required: ["reason"]
    //   }
    // },
    // {
    //   name: "searchWeb",
    //   description: "在互联网上搜索内容",
    //   parameters: {
    //     type: "object",
    //     properties: {
    //       query: {
    //         type: "string"
    //       }
    //     },
    //     required: ["query"]
    //   }
    // },
    // {
    //   name: "getUrl",
    //   description: "获取url内容",
    //   parameters: {
    //     type: "object",
    //     properties: {
    //       url: {
    //         type: "string"
    //       }
    //     },
    //     required: ["url"]
    //   }
    // }


  ]
);



// 更新块内容，根据换行符将内容分段
const updateBlocks = () => {
  const paragraphs = editorContent.value.split('\n');
  blocks.value = paragraphs.map(paragraph => ({
    id: generateRandomId(),
    type: 'text',
    content: paragraph
  }));
};

// 处理 Enter 键
const handleEnter = (event) => {
  if (!event.shiftKey) {
    event.preventDefault(); // 禁用 Enter 换行行为
    const caretPosition = event.target.selectionStart;
    editorContent.value = editorContent.value.slice(0, caretPosition) + '\n' + editorContent.value.slice(caretPosition);
    nextTick(() => {
      event.target.selectionStart = caretPosition + 1;
      event.target.selectionEnd = caretPosition + 1;
      updateBlocks();
    });
  }
};

// const runTool = async (toolCalls) => {
//   console.log('工具调用: ', JSON.stringify(toolCalls));
//   let toolMessagesList = [];
//   for (const item of toolCalls) {
//     let { id, function: func } = item;
//     let { name, arguments: args } = func;
//     let funContent = '';

//     try {
//       // 4. 尝试解析 arguments 字符串为 JSON 对象
//       const parsedArgs = JSON.parse(args);
//       console.log('工具调用解析成功', parsedArgs);

//       // 5. 解析成功后，处理工具调用逻辑
//       funContent = parsedArgs;
//     } catch (error) {
//       console.log('解析错误: ' + error.message);
//       console.log('解析错误的原始内容: ', args);  // 注意，这里输出的是已经处理过的 args
//       console.log('解析错误的原始工具内容: ', JSON.stringify(toolCalls, null, 2));
//     }

//     const parsedArgs = JSON.parse(args);

//     switch (name) {
//       case 'write':
//         {
//           const { content } = parsedArgs;
//           editorContent.value += '\n' + content; // 将新内容追加到编辑器内容
//           updateBlocks(); // 更新块
//           funContent = `已写入内容`;
//         }
//         break;

//       case 'edit':
//         {
//           const { blockId, newContent } = parsedArgs;
//           const block = blocks.value.find((b) => b.id === blockId);
//           if (block) {
//             block.content = newContent;
//             editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
//             funContent = `编辑块 ${blockId} 成功`;
//           } else {
//             funContent = `找不到 ID 为 ${blockId} 的块。`;
//           }
//         }
//         break;

//       case 'insert':
//         {
//           const { blockId, newContent } = parsedArgs;
//           const index = blocks.value.findIndex(block => block.id === blockId);
//           if (index !== -1) {
//             const newBlock = {
//               id: generateRandomId(),
//               type: 'text',
//               content: newContent
//             };
//             blocks.value.splice(index + 1, 0, newBlock); // 在指定块之后插入新块
//             editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
//             funContent = `在块 ${blockId} 之后插入了新内容`;
//           } else {
//             funContent = `找不到 ID 为 ${blockId} 的块。`;
//           }
//         }
//         break;

//       case 'delete':
//         {
//           const { blockId } = parsedArgs;
//           const index = blocks.value.findIndex(block => block.id === blockId);
//           if (index !== -1) {
//             blocks.value.splice(index, 1); // 删除指定块
//             editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
//             funContent = `删除了 ID 为 ${blockId} 的块。`;
//           } else {
//             funContent = `找不到 ID 为 ${blockId} 的块。`;
//           }
//         }
//         break;

//       case 'clear':
//         {
//           blocks.value = [{
//             id: generateRandomId(),
//             type: 'text',
//             content: ''
//           }];
//           editorContent.value = ''; // 清空编辑器内容
//           funContent = '编辑器已清空。';
//         }
//         break;

//       case 'searchWeb':
//         {
//           try {
//             const response = await post('/api/assistant/tools/search', parsedArgs); // 等待 post 完成
//             funContent = JSON.stringify(response); // 获取并设置搜索结果
//           } catch (error) {
//             console.error(error);
//             funContent = '搜索出错了。';
//           }
//         }
//         break;

//       case 'getUrl':
//         {
//           try {
//             const response = await post('/api/assistant/tools/url', parsedArgs); // 等待 post 完成
//             funContent = JSON.stringify(response); // 获取并设置URL结果
//           } catch (error) {
//             console.error(error);
//             funContent = '获取URL时出错了。';
//           }
//         }
//         break;
//       default:
//         funContent = `未识别的工具调用: ${name}`;
//     }

//     toolMessagesList.push({
//       tool_call_id: id,
//       role: 'tool',
//       name: name,
//       content: funContent,
//       // expanded: false,
//     });
//   }
//   addVersion()
//   return toolMessagesList;
// };

// 复制和下载功能


const runTool = async (toolCalls) => {
  console.log('工具调用: ', JSON.stringify(toolCalls));
  let toolMessagesList = [];

  for (const item of toolCalls) {
    let { name, args } = item;

    let funContent = '';

    try {
      // 解析 arguments 字符串为 JSON 对象
      const parsedArgs = typeof args === 'string' ? JSON.parse(args) : args;
      console.log('工具调用解析成功', parsedArgs);

      // 根据工具名称执行相应的逻辑
      switch (name) {
        case 'write':
          {
            const { content } = parsedArgs;
            editorContent.value += '\n' + content; // 将新内容追加到编辑器内容
            updateBlocks(); // 更新块
            funContent = `已写入内容`;
          }
          break;

        case 'edit':
          {
            const { blockId, newContent } = parsedArgs;
            const block = blocks.value.find((b) => b.id === blockId);
            if (block) {
              block.content = newContent;
              editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
              funContent = `编辑块 ${blockId} 成功`;
            } else {
              funContent = `找不到 ID 为 ${blockId} 的块。`;
            }
          }
          break;

        case 'insert':
          {
            const { blockId, newContent } = parsedArgs;
            const index = blocks.value.findIndex(block => block.id === blockId);
            if (index !== -1) {
              const newBlock = {
                id: generateRandomId(),
                type: 'text',
                content: newContent
              };
              blocks.value.splice(index + 1, 0, newBlock); // 在指定块之后插入新块
              editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
              funContent = `在块 ${blockId} 之后插入了新内容`;
            } else {
              funContent = `找不到 ID 为 ${blockId} 的块。`;
            }
          }
          break;

        case 'delete':
          {
            const { blockId } = parsedArgs;
            const index = blocks.value.findIndex(block => block.id === blockId);
            if (index !== -1) {
              blocks.value.splice(index, 1); // 删除指定块
              editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
              funContent = `删除了 ID 为 ${blockId} 的块。`;
            } else {
              funContent = `找不到 ID 为 ${blockId} 的块。`;
            }
          }
          break;

        case 'clear':
          {
            blocks.value = [{
              id: generateRandomId(),
              type: 'text',
              content: ''
            }];
            editorContent.value = ''; // 清空编辑器内容
            funContent = '编辑器已清空。';
          }
          break;

        case 'searchWeb':
          {
            try {
              const response = await post('/api/assistant/tools/search', parsedArgs); // 等待 post 完成
              funContent = JSON.stringify(response); // 获取并设置搜索结果
            } catch (error) {
              console.error(error);
              funContent = '搜索出错了。';
            }
          }
          break;

        case 'getUrl':
          {
            try {
              const response = await post('/api/assistant/tools/url', parsedArgs); // 等待 post 完成
              funContent = JSON.stringify(response); // 获取并设置URL结果
            } catch (error) {
              console.error(error);
              funContent = '获取URL时出错了。';
            }
          }
          break;

        default:
          funContent = `未识别的工具调用: ${name}`;
      }

    } catch (error) {
      console.log('解析错误: ' + error.message);
      console.log('解析错误的原始内容: ', args);
      console.log('解析错误的原始工具内容: ', JSON.stringify(toolCalls, null, 2));
      funContent = `工具调用解析错误: ${error.message}`;
    }

    // 构建 functionResponse 部分
    const functionResponse = {
      role: "user",
      parts: [
        {
          functionResponse: {
            name: name,
            response: {
              content: funContent
            }
          }
        }
      ]
    };

    toolMessagesList.push(functionResponse);
  }

  addVersion(); // 更新版本（确保此函数已定义）

  return toolMessagesList;
};


const copyContent = () => {
  navigator.clipboard.writeText(editorContent.value)
    .then(() => alert('内容已复制'))
    .catch(() => alert('复制失败'));
};

const downloadContent = () => {
  const blob = new Blob([editorContent.value], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `content-${Date.now()}.txt`;
  a.click();
  URL.revokeObjectURL(url);
};

// 添加版本记录
const addVersion = () => {
  const newVersion = {
    blocks: JSON.parse(JSON.stringify(blocks.value)),
    timestamp: new Date().toLocaleString()
  };
  versionHistory.value.unshift(newVersion);
};

// 恢复版本
const restoreVersion = (index) => {
  const version = versionHistory.value[index];
  blocks.value = JSON.parse(JSON.stringify(version.blocks));
  editorContent.value = blocks.value.map(block => block.content).join('\n');
};

// 导出版本历史
const exportVersionHistory = () => {
  const blob = new Blob([JSON.stringify(versionHistory.value)], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `version-history-${Date.now()}.json`;
  a.click();
  URL.revokeObjectURL(url);
};

// 导入版本历史
const importVersionHistory = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const importedData = JSON.parse(e.target.result);
        versionHistory.value = importedData;
      } catch (err) {
        alert('导入文件格式错误');
      }
    };
    reader.readAsText(file);
  }
};

onMounted(() => {
  nextTick(() => {
    updateBlocks();
  });
});
</script>
